import * as s from './Styles';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { ReactComponent as BurgerMenu } from 'assets/img/burgerMenu.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg';
import { useEffect, useState, useContext, useCallback } from 'react';
import { ModalContext } from 'App';
import TopMenu from 'components/TopMenu/TopMenu';
import { menuItemType } from 'api/api';

const MobileMenu = ({ data: menuData }: { data: menuItemType[] }) => {
  const modalControl = useContext(ModalContext);

  const [isScrolled, setIsScrolled] = useState(0);

  useEffect(() => {
    function watchScroll() {
      const scrollPos = window.scrollY;

      if (scrollPos > 15) {
        setIsScrolled(scrollPos);
      } else {
        setIsScrolled(0);
      }
    }
    window.addEventListener('scroll', watchScroll);
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  });

  const IsModalOpen = useCallback(() => {
    return Boolean(modalControl?.state);
  }, [modalControl?.state]);

  function menuHandler() {
    const data = {
      title: 'MobileMenu',
      type: '',
      body: (
        <div>
          <TopMenu
            data={menuData}
            vertical
          />
        </div>
      ),
    };

    if (IsModalOpen()) {
      modalControl?.update(null);
    } else {
      modalControl?.update(data);
    }
  }

  return (
    <>
      {!IsModalOpen() && (
        <s.MobMenuBox id='MobMenu'>
          <div
            onClick={() => menuHandler()}
            className='burger'>
            {IsModalOpen() ? (
              <>
                <ArrowLeft />
              </>
            ) : (
              <BurgerMenu />
            )}
          </div>
          {!isScrolled && <Logo />}
        </s.MobMenuBox>
      )}
    </>
  );
};

export default MobileMenu;
