import * as s from './Styles';

import Button from 'components/Button/Button';
import 'animate.css/animate.min.css';
import { useContext } from 'react';

import BuyCard from 'components/BuyCard/BuyCard';
import { shopDataType, useFetchData } from 'api/api';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ModalContext } from 'App';
import { VideoModalShipments } from 'pages/MainPage/Styles';

const Delivery = () => {
  const shopPageData = useFetchData({
    path: 'shop-page-content',
  }) as shopDataType;

  const { title, description, pageTextContent, shopList } = shopPageData || {};

  const modalControl = useContext(ModalContext);

  function openShipmentsBtn(ShipmentModalTitle: string, shipmentsLinks: any[]) {
    let innerContent = '<div class="shipment-list-cont"><div class="shipment-list-title">' + ShipmentModalTitle + '</div><ul>';

    for (let item of shipmentsLinks) {

      let shipment_link = process.env.REACT_APP_STRIPE_SCRIPT_URL + '?' + Object.entries(item)
        .flatMap(([key, value]) => [value].flat().map(v => [key, v]))
        .map(it => it.join("="))
        .join("&");

      innerContent += '<li><a href="' + shipment_link + '">' + item.country + '</a></li>';
    }

    innerContent += '</ul></div>';

    const data = {
      title: 'MobileMenu',
      type: "shipment",
      body: <VideoModalShipments><div dangerouslySetInnerHTML={{ __html: innerContent }}></div></VideoModalShipments>,
    };
    modalControl?.update(data);
  }

  return shopPageData ? (
    <>
      <div className='wrap'>
        <s.Title as='h1'>
          {title}
          <small>{description}</small>
        </s.Title>
        <s.CardArea style={{ marginBottom: '5vh' }}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 860: 1, 861: 2 }}>
            <Masonry gutter='3em'>
              {shopList.map((x, i) => {
                let fiatListData: any[] = [];

                x.shopFeatList.forEach((item) => {
                  fiatListData.push({
                    text: item.featText,
                    iconColor: x.hasNanny ? '#85CCDE' : '#9aab58',
                  });
                });

                let buttonsData: any[] = [];

                var stripe_session_links_args: any = [];


                if (x.StripeFields) {

                  for (let field of x.StripeFields) {

                    if ((typeof (x.ProductPriceId) == 'string' && x.ProductPriceId !== '')
                      && (typeof (field.ShipmentId) == 'string' && field.ShipmentId !== '')
                      && (typeof (field.CountryCode) == 'string' && field.CountryCode !== '')
                      && (typeof (field.CountryName) == 'string' && field.CountryName !== '')
                    ) {
                      stripe_session_links_args.push({
                        'price': x.ProductPriceId,
                        'shimpments': field.ShipmentId,
                        'countries': field.CountryCode,
                        'country': field.CountryName
                      });
                    }
                  }

                }

                // console.log(stripe_session_links_args);
                // const query = Object.entries(stripe_session_link_args)
                //   .flatMap(([key, value]) => [value].flat().map(v => [key, v]))
                //   .map(it => it.join("="))
                //   .join("&");


                x.shopButtons.forEach((button, i) => {

                  let button_link = button.link;

                  if (stripe_session_links_args.length > 0) {
                    buttonsData.push(
                      <Button
                        icon={
                          <img
                            width={35}
                            src={transformImageUri(button.icon?.url)}
                            alt={button.text}
                          />
                        }
                        text={button.text}
                        style={{
                          background: button.backgroundColor,
                          color: '#fff',
                          textShadow: 'none',
                        }}
                        onClick={() => openShipmentsBtn(x.ShipmentModalTitle, stripe_session_links_args)}
                      />
                    );

                  } else {
                    buttonsData.push(
                      <a
                        key={i}
                        target='_blank'
                        href={button_link}
                        rel='noreferrer'>
                        <Button
                          icon={
                            <img
                              width={35}
                              src={transformImageUri(button.icon?.url)}
                              alt={button.text}
                            />
                          }
                          text={button.text}
                          style={{
                            background: button.backgroundColor,
                            color: '#fff',
                            textShadow: 'none',
                          }}
                        />
                      </a>
                    );
                  }



                });

                return (
                  <BuyCard
                    hasNanny={x.hasNanny}
                    hasNannyText={x.hasNannyText}
                    key={i}
                    image={transformImageUri(x.image?.url)}
                    title={x.title}
                    listContent={fiatListData}
                    buttons={buttonsData}
                    slug={x.Slug}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </s.CardArea>
        <div style={{ clear: 'both' }}></div>
        <s.Text>
          <ReactMarkdown
            transformImageUri={transformImageUri}
            children={pageTextContent || ''}
          />
        </s.Text>
      </div>
    </>
  ) : null;
};

export default Delivery;
