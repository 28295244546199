import styled, { keyframes } from 'styled-components'
import videoBoxBg from 'assets/img/videoBoxBg.svg'



export const CardArea = styled.div`
display: grid;
position: relative;
grid-template-columns: 1fr 1fr 1fr;
gap: 5vh;
justify-items: center;
margin-bottom: 10vh;

&>* {
    position: relative;
    width: 100%;
}


@media (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
}
@media (max-width: 560px) {
    grid-template-columns: 1fr;
}
`

export const VideoBox = styled.div`
background-image: url(${videoBoxBg});
background-position: 50% 0;
background-size: 100% 100%;
background-repeat: no-repeat;
padding-bottom:15vw;
margin-bottom: calc(-15vw + 6vh);


@media (max-width: 1500px) {
    background-size: 150% 100%;
}
@media (max-width: 700px) {
    background-size: 250% 100%;
}
`

export const VideoGrid = styled.div`
position: relative;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 5vh;
justify-items: center;

&>* {
    position: relative;
    width: 100%;
}
@media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
}
@media (max-width: 700px) {
    grid-template-columns: 1fr;
}
`


export const StairsBox = styled.div`
position: relative;
display: flex;
flex-flow: column;
gap: 5vh;

@media (min-width:900px) {
    &>div:nth-child(even)>div {
    transform: translateX(3vw);
}

&>div:nth-child(odd)>div {
    transform: translateX(-3vw);
}
}

`

const anim = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
   
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;


export const VideoModal = styled.div`
position: relative;
width: 80%;
height: 70vh;

iframe {
    animation: ${anim} .3s ease;
    animation-delay: 300ms;
    animation-fill-mode: backwards;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
`
export const VideoModalShipments = styled.div`
position: relative;
width: 80%;
height: 70vh;

iframe {
    animation: ${anim} .3s ease;
    animation-delay: 300ms;
    animation-fill-mode: backwards;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
`
