import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import Modal from 'components/Modal/Modal';
import ScrollTop from 'components/ScrollTop/ScrollToTop';
import Header from 'components/Header/Header';
import { footerHeaderType, useFetchData } from 'api/api';
import useLang from 'utils/useLang';
import { Suspense } from 'react';
import MainPage from 'pages/MainPage/MainPage';
// const Useful = React.lazy(() => import('pages/Useful/Useful'));
// const AppDownload = React.lazy(() => import('pages/AppDownload/AppDownload'));
// const Footer = React.lazy(() => import('components/Footer/Footer'));
// const Library = React.lazy(() => import('pages/Library/Library'));
// const Help = React.lazy(() => import('pages/Help/Help'));
// const Delivery = React.lazy(() => import('pages/Delivery/Delivery'));
// const Warranty = React.lazy(() => import('pages/Warranty/Warranty'));
// const Good = React.lazy(() => import('pages/Good/Good'));

import Useful from 'pages/Useful/Useful';
import AppDownload from 'pages/AppDownload/AppDownload';
import Footer from 'components/Footer/Footer';
import Library from 'pages/Library/Library';
import Help from 'pages/Help/Help';
import Delivery from 'pages/Delivery/Delivery';
import Warranty from 'pages/Warranty/Warranty';
import Good from 'pages/Good/Good';

type ModalObj = {
  title: string;
  type: string;
  body: React.ReactNode;
};

type ContextObj = {
  state: ModalObj | null;
  update: React.Dispatch<React.SetStateAction<ModalObj | null>>;
};

export const ModalContext = React.createContext<ContextObj | null>(null);

function App() {
  let location = useLocation();
  useLang();

  const [modalContent, setModalContent] = useState<ModalObj | null>(null);

  useEffect(() => {
    setModalContent(null);
  }, [location.pathname]);

  const headerFooterData = useFetchData({
    path: 'header-footer-content',
  }) as footerHeaderType;

  useFetchData({
    path: 'main-page-content',
  });

  useFetchData({
    path: 'compare',
  });

  useFetchData({
    path: 'warranty-page-content',
  });
  useFetchData({
    path: 'help-page-content',
  });
  useFetchData({
    path: 'library-page-content',
  });

  useFetchData({
    path: 'shop-page-content',
  });

  return (
    <>
      <ModalContext.Provider
        value={{
          state: modalContent,
          update: setModalContent,
        }}>
        <ScrollTop />
        <MobileMenu data={headerFooterData?.menu} />
        <Header
          data={headerFooterData}
          $small={location.pathname === '/' ? false : true}
        />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path='/'
              element={<MainPage />}
            />
            <Route
              path='/delivery'
              element={<Delivery />}
            />
            <Route
              path='/shop'
              element={<Delivery />}
            />
            <Route
              path='/shop/*'
              element={<Good />}
            />
            <Route
              path='/warranty'
              element={<Warranty />}
            />
            <Route
              path='/library'
              element={<Library />}
            />
            <Route
              path='/help'
              element={<Help />}
            />
            <Route
              path='/useful'
              element={<Useful />}
            />

            <Route
              path='/appdownload'
              element={<AppDownload />}
            />

            <Route
              path='/appdownloadnonanny'
              element={<AppDownload />}
            />

            <Route
              path='*'
              element={<div>No template for {location.pathname}</div>}
            />
          </Routes>
        </Suspense>
        {headerFooterData ? (
          <Footer data={headerFooterData.footerContent} />
        ) : null}
        <Modal />
      </ModalContext.Provider>
    </>
  );
}

export default App;
